






























































































































import base64Images from "@/modules/base64-images";
import Request from "@/modules/request";
import { VideoType } from "@/store/interfaces/VideoType";
import { MediaType } from "@/store/interfaces/MediaType";
import Vue from "vue";
import Tag from "@/store/models/Tag";

export default Vue.extend({
  props: {
    videoBody: {
      required: true,
    },
    videoId: {
      type: Number,
      required: true,
    },
    typeOverride: {
      type: String,
      required: false,
    },
  },
  computed: {
    processedTags: {
      get(): string[] {
        console.log(this.tags);
        const array = [];
        for (const tag of this.tags) {
          if (typeof tag == "object") {
            array.push((tag as Tag).value);
          } else {
            array.push(tag);
          }
        }
        return array;
      },
      set(value: string[]) {
        this.tags = value;
        console.log(this.tags);
      },
    },
    tagsList() {
      const array = [];
      for (const tag of this.$store.state.tags as Tag[]) {
        array.push(tag.value);
      }
      return array;
    },
    parsedType() {
      switch (this.details!.type) {
        case VideoType.move:
          return "Bouger";
        case VideoType.health:
          return "Santé";
        case VideoType.trailer:
          return "Trailer";
        case VideoType.series:
          return "Séries";
        default:
          return "Inconnu";
      }
    },
    thumbnailFile: {
      get() {
        return this.file as File;
      },
      set(value: File) {
        try {
          if (value) {
            if (this.validateThumbnail(value)) {
              this.file = value;
              this.thumbnailSrc = URL.createObjectURL(value);
            } else {
              this.file = null;
              this.thumbnailSrc = base64Images.thumbnailPlaceholder;
            }
          } else {
            this.file = value;
            this.thumbnailSrc = base64Images.thumbnailPlaceholder;
          }
        } catch (error) {
          this.$store.dispatch("showAlert", {
            message: error.message,
            color: "warning",
            timeout: 4000,
          });
        }
      },
    },
  },
  data: () => ({
    configureLoading: false,
    tagDialog: false,
    categoryId: 0,
    bodypartId: 0,
    tags: [] as Array<string | Tag>,
    tagSearch: null,
    //details: null as null | Record<string, any>,
    details: {
      name: "placeholder",
      encoded: false,
      type: VideoType.move,
    } as Record<string, any> | null,
    thumbnailSrc: base64Images.thumbnailPlaceholder,
    file: null as null | File,
    timeout: 0,
  }),
  methods: {
    parseTypeEnum(type: VideoType) {
      switch (type) {
        case VideoType.move:
          return "move";
        case VideoType.health:
          return "health";
      }
    },
    closeDialog() {
      this.details = null;
      this.file = null;
      this.thumbnailSrc = base64Images.thumbnailPlaceholder;
      this.tags = []
      this.$emit("close");
    },
    async loadDetails(id: number) {
      const response = await Request.shared.get(`video/${this.videoId}`);
      if (response.status == 200) {
        this.details = { ...response.data };
      }
    },
    getVideoDetails(id: number, context: any) {
      // During config, get information about encoding at regular intervals
      this.timeout = setInterval(async () => {
        if (context.details && !context.details.encoded) {
          // We refresh the details of the video currently uploading
          const response = await Request.shared.get(`video/${this.videoId}`);
          if (response.status == 200) {
            context.details = { ...response.data };
          }
        }
      }, 5000) as unknown as number;
    },
    selectImage() {
      (this.$refs.thumbnailSelector as any).$refs.input.click();
    },
    handleDrop(e: DragEvent) {
      if (e.dataTransfer!.files.length) {
        this.thumbnailFile = e.dataTransfer!.files[0];
      }
    },
    validateThumbnail(file: File) {
      if (file.type == "image/png" || file.type == "image/jpeg") {
        // We check the file size
        if (file.size < 300000) {
          return true;
        } else {
          throw {
            message:
              "Le fichier est trop volumineux, sa taille doit être inférieure à 300ko",
          };
        }
      } else {
        throw {
          message: "Veuillez utiliser un fichier .jpg ou .png",
        };
      }
    },
    async configureVideo() {
      try {
        this.configureLoading = true;

        if (this.thumbnailFile) {
          const mediaBody = {
            type: MediaType.thumbnail,
            alt: this.thumbnailFile.name,
          };
          // We create the formdata
          const formData = new FormData();
          formData.append("body", JSON.stringify(mediaBody));
          formData.append("media", this.thumbnailFile);
          const media = await Request.shared.post("media", formData);
          // Once we got the media, we can assign the thumbnail to the video
          const assignedThumbnail = await Request.shared.put(
            `video/${this.videoId}/thumbnail/${media.data.id}`
          );
        }
        if (this.categoryId) {
          // Once the thumbnail is assigned, we can assign the Category
          const assignedCategory = await Request.shared.put(
            `video/${this.videoId}/category/${this.categoryId}`
          );
        }
        if (this.bodypartId) {
          const bodyPartSet = await Request.shared.put(
            `video/${this.videoId}/bodypart/${this.bodypartId}`
          );
        }
        if (this.tags) {
          let tagFound = undefined as Tag | undefined;
          // Tag handling (we are forced to cycle through every tag)
          for (const tag of this.processedTags) {
            if (typeof tag == "object") {
              // We try to find a tag with the same name (case insensitive)
              tagFound = (this.$store.state.tags as Tag[]).find((value) => {
                return (tag as Tag).value == value.value;
              });
            } else {
              // We try to find a tag with the same name (case insensitive)
              tagFound = (this.$store.state.tags as Tag[]).find((value) => {
                return tag == value.value;
              });
            }

            if (tagFound) {
              // If the tag exists, we assign it
              const assignedTag = await Request.shared.post(
                `video/${this.videoId}/tag/${tagFound.id}`
              );
            } else {
              // We need to create the tag first, then assign it
              const postedTag = await Request.shared.post("tag", {
                value: tag,
              });
              const assignedTag = await Request.shared.post(
                `video/${this.videoId}/tag/${postedTag.data.id}`
              );
            }
          }
        }
        this.$store.dispatch("showAlert", {
          message: "La vidéo a été configurée!",
          color: "success",
        });
        // We refresh the videos and the tags
        this.$store.dispatch("getVideos");
        this.$store.dispatch("getTags");
        this.closeDialog();
        this.configureLoading = false;
      } catch (error) {
        this.$store.dispatch("showAlert", {
          message: "Impossible de configurer cette vidéo pour le moment",
          color: "warning",
          timeout: 4000,
        });
      }
    },
  },
  watch: {
    videoId() {
      if (this.videoId) {
        this.loadDetails(this.videoId);
        this.getVideoDetails(this.videoId, this);
      } else {
        clearInterval(this.timeout);
      }
    },
  },
});
