var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('h1',[_vm._v("Videos")]),_c('div',{staticClass:"text-center mb-5"},[_c('v-btn',{attrs:{"color":"black","dark":""},on:{"click":function($event){_vm.addDialog = true}}},[_c('v-icon',{staticClass:"mr-3"},[_vm._v("mdi-video")]),_vm._v(" Ajouter une vidéo")],1)],1),_c('v-row',{staticClass:"d-flex"},[_c('v-col',[_c('v-progress-linear',{staticClass:"diskfree ml-auto rounded rounded-md elevation-1 white--text",attrs:{"color":_vm.diskInfo.size == 0 ? 'black' : _vm.diskColor,"value":_vm.diskPercentage,"indeterminate":_vm.diskInfo.size == 0,"height":"25px"}},[(_vm.diskInfo.size !== 0)?_c('div',[_vm._v(" "+_vm._s(_vm.prettyDiskInfo.used)+"/"+_vm._s(_vm.prettyDiskInfo.size)+" - "),_c('strong',[_vm._v(_vm._s(_vm.prettyDiskPercentage))])]):_vm._e()])],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.$store.state.videos,"loading":_vm.loadContent,"loading-text":"Chargement en cours"},on:{"click:row":_vm.openEditDialog,"current-items":_vm.currentItems},scopedSlots:_vm._u([{key:"item.thumbSrc",fn:function(ref){
var item = ref.item;
return [_c('v-img',{staticClass:"my-2",attrs:{"width":"128px","max-height":"72px","src":item.thumbnailSrc,"cover":""}},[_c('div',{staticClass:"ma-2 pa-1 embedded-duration"},[_vm._v(" "+_vm._s(_vm.formattedDuration(item.length))+" ")])])]}},{key:"item.available",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{attrs:{"color":"black","disabled":!item.encoded},on:{"click":function($event){$event.stopPropagation();return _vm.toggleVisibility(item)}},model:{value:(item.available),callback:function ($$v) {_vm.$set(item, "available", $$v)},expression:"item.available"}})]}},{key:"item.encodingString",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getEncodingStatus(item))+" ")]}},{key:"item.tools",fn:function(){return [_c('v-btn',{attrs:{"icon":"","color":"blue"}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)]},proxy:true},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('v-icon',[_vm._v(_vm._s(item.type == 0 ? "mdi-weight-lifter" : "mdi-hospital-box"))])]}},{key:"item.difficulty",fn:function(ref){
var item = ref.item;
return [(item.type == 0)?_c('v-icon',[_vm._v(_vm._s(_vm.getDifficultyIcon(item.difficulty)))]):_c('div',[_vm._v("N/A")])]}}],null,true)}),_c('add-video-dialog',{attrs:{"show":_vm.addDialog},on:{"close":_vm.closeAddDialog}}),_c('edit-video-dialog',{attrs:{"show":_vm.editDialog,"id":_vm.editIndex},on:{"close":_vm.closeEditDialog,"updated":_vm.postUpdateCycle}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }