

















































import Vue from "vue";
import Step1 from "@/components/AddDialogStepper/Step1.vue";
import Step2 from "@/components/AddDialogStepper/Step2.vue";
import Step3 from "@/components/AddDialogStepper/Step3.vue";
import Step4 from "@/components/AddDialogStepper/Step4.vue";
import { Level } from "@/store/interfaces/Level";
import { VideoType } from "@/store/interfaces/VideoType";
import Request from "@/modules/request";
import { AxiosResponse } from "axios";

export default Vue.extend({
  components: {
    Step1,
    Step2,
    Step3,
    Step4,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    videoType: {
      type: String,
      required: false,
    },
    seriesId: {
      type: Number,
      required: false,
    },
  },
  computed: {
    showDialog: {
      get(): boolean {
        return this.show;
      },
      set(value: boolean) {
        if (!value) {
          // Emits the video ID into the close event
          this.$emit("close", this.id);
          this.resetDialog();
        }
      },
    },
  },
  data: () => ({
    preventClose: false,
    step: 1,
    videoFile: null as null | File,
    videoBody: {
      name: "",
      description: "",
      difficulty: Level.rookie,
      type: VideoType.move,
    } as Record<string, any>,
    progressEvent: null as ProgressEvent | null,
    uploaded: false,
    id: 0,
  }),
  methods: {
    resetDialog() {
      this.videoFile = null;
      this.step = 1;
      this.preventClose = false;
      this.id = 0;
      this.videoBody = {
        name: "",
        description: "",
        difficulty: Level.rookie,
        type: VideoType.move,
      };
      //TBD
    },
    changeFile(file: File) {
      this.videoFile = file;
    },
    upload(payload: Record<string, any>) {
      this.videoBody = payload;
      this.preventClose = true;
      if (this.videoFile) {
        Request.postVideo(
          this.videoFile,
          this.videoBody,
          (e) => {
            this.progressEvent = e;
          },
          (response: AxiosResponse) => {
            this.uploaded = true;
            this.id = response.data.id;
            this.step = 4;
          },
          (error) => {
            console.log(error);
            this.$store.dispatch("showAlert", {
              message: error.message,
              color: "warning",
              timeout: 3000,
            });
            this.resetDialog();
          }
        );
      }
      this.step = 3;
    },
  },
});
