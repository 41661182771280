


























































import rules from "@/modules/rules";
import { Level } from "@/store/interfaces/Level";
import { VideoType } from "@/store/interfaces/VideoType";
import Vue from "vue";

export default Vue.extend({
  props: {
    typeOverride: {
      type: String,
    },
  },
  computed: {
    computedVideoType: {
      get(): VideoType {
        if (!this.typeOverride) {
          return this.type;
        } else if (this.typeOverride == "series") {
          return VideoType.series;
        } else if (this.typeOverride == "trailer") {
          return VideoType.trailer;
        } else {
          return this.type;
        }
      },
    },
  },
  data() {
    return {
      formValid: false,
      name: "",
      type: -1,
      difficulty: 0,
      description: "",
      typeElements: [
        {
          id: VideoType.move,
          title: "Bouger",
        },
        {
          id: VideoType.health,
          title: "Santé",
        },
      ],
      difficultyElements: [
        {
          id: Level.rookie,
          title: "Débutant",
        },
        {
          id: Level.intermediate,
          title: "Intermédiaire",
        },
        {
          id: Level.pro,
          title: "Confirmé",
        },
      ],
      rules: rules(this),
    };
  },
  methods: {
    goBack() {
      this.resetSlide();
      this.$emit("back");
    },
    upload() {
      this.$emit("upload", {
        name: this.name,
        type: this.computedVideoType,
        difficulty: this.difficulty,
        description: this.description,
      });
      this.resetSlide();
    },
    resetSlide() {
      this.name = "";
      this.type = -1;
      this.difficulty = 0;
      this.description = "";
      (this.$refs.addVideoStep2Form as any).resetValidation();
    },
  },
});
