






















import prettyBytes from "pretty-bytes";
import Vue from "vue";

export default Vue.extend({
  props: {
    progressEvent: {
      required: true,
    },
  },
  computed: {
    formattedEvent() {
      if (!this.progressEvent) {
        return "0 / ? Mo - 0%";
      } else {
        const event = this.progressEvent as ProgressEvent;
        return `${prettyBytes(event.loaded)} / ${prettyBytes(
          event.total
        )} - ${Math.floor((event.loaded * 100) / event.total)}%`;
      }
    },
    progressPercent() {
      if (!this.progressEvent) {
        return 0;
      } else {
        const event = this.progressEvent as ProgressEvent;
        return Math.floor((event.loaded * 100) / event.total);
      }
    },
  },
});
