







































































import Vue from "vue";
import prettyBytes from "pretty-bytes";

export default Vue.extend({
  props: {
    file: {
      required: true,
    },
  },
  computed: {
    selectedFile: {
      get(): File {
        return this.file as File;
      },
      set(value: File) {
        console.log("file set")
        try {
          if (value) {
            if (this.validateFile(value)) {
              this.sendFileDataToParent(value);
            } else {
              this.sendFileDataToParent(null);
            }
          } else {
            this.sendFileDataToParent(value);
          }
        } catch (error) {
          this.$store.dispatch("showAlert", {
            message: error.message,
            color: "warning",
            timeout: 4000,
          });
        }
      },
    },
  },
  methods: {
    selectFile() {
      (this.$refs.fileSelector as any).$refs.input.click();
    },
    dropFile(e: DragEvent) {
      if (e.dataTransfer!.files.length) {
        const file = e.dataTransfer!.files[0];
        this.selectedFile = file;
      }
    },
    sendFileDataToParent(file: File | null) {
      this.$emit("fileChanged", file);
    },
    formatFileSize(size: number) {
      return prettyBytes(size);
    },
    validateFile(file: File) {
      if (file.type == "video/mp4" || file.type == "video/quicktime") {
        // We check the file size
        if (file.size < 4294967296) {
          return true;
        } else {
          throw {
            message:
              "Le fichier est trop volumineux, sa taille doit être inférieure à 4Go",
          };
        }
      } else {
        throw {
          message: "Veuillez utiliser un fichier .mp4 ou .mov au format h264",
        };
      }
    },
  },
});
