
































































































































































import base64Images from "@/modules/base64-images";
import Request from "@/modules/request";
import { Level } from "@/store/interfaces/Level";
import { MediaType } from "@/store/interfaces/MediaType";
import { VideoType } from "@/store/interfaces/VideoType";
import Category from "@/store/models/Category";
import Tag from "@/store/models/Tag";
import Video from "@/store/models/Video";
import Vue from "vue";
import rules from "@/modules/rules";

export default Vue.extend({
  props: {
    id: {
      type: Number,
      required: true,
    },
    show: {
      type: Boolean,
      required: true,
    },
    typeOverride: {
      type: String,
      required: false,
    },
  },
  computed: {
    processedTags: {
      get(): string[] {
        console.log(this.tags);
        const array = [];
        for (const tag of this.tags) {
          if (typeof tag == "object") {
            array.push((tag as Tag).value);
          } else {
            array.push(tag);
          }
        }
        return array;
      },
      set(value: string[]) {
        this.tags = value;
        console.log(this.tags);
      },
    },
    tagsList() {
      const array = [];
      for (const tag of this.$store.state.tags as Tag[]) {
        array.push(tag.value);
      }
      return array;
    },
    videoCategories: {
      get(): Category[] {
        return this.$store.state.categories[
          this.parseTypeEnum(this.video!.type)
        ];
      },
    },
    thumbnailFile: {
      get(): File | null {
        return this.thumbnail as File;
      },
      set(value: File) {
        try {
          if (value) {
            if (this.validateThumbnail(value)) {
              this.thumbnail = value;
              this.thumbnailSrc = URL.createObjectURL(value);
              this.thumbnailChanged = true;
            } else {
              this.thumbnail = null;
              this.thumbnailSrc = base64Images.thumbnailPlaceholder;
            }
          } else {
            this.thumbnail = value;
            this.thumbnailSrc = base64Images.thumbnailPlaceholder;
            this.thumbnailChanged = true;
          }
        } catch (error) {
          this.$store.dispatch("showAlert", {
            message: error.message,
            color: "warning",
            timeout: 4000,
          });
        }
      },
    },
    showDialog: {
      get(): boolean {
        return this.show;
      },
      set(value: boolean) {
        if (!value) {
          this.video = null;
          this.categoryId = 0;
          this.coachId = 0;
          this.bodyPartId = 0;
          this.tags = [];
          this.videoObjectLoaded = false;
          this.videoObjectEdited = false;
          this.thumbnailSrc = base64Images.thumbnailPlaceholder;
          (this.$refs.editVideoForm as any).resetValidation();
          this.$emit("close");
          setTimeout(() => {
            this.thumbnailFile = null;
            this.thumbnailChanged = false;
            this.videoLoading = true;
          }, 300);
        }
      },
    },
    parsedType: {
      get(): string {
        if (this.video) {
          switch (this.video.type) {
            case VideoType.move:
              return "Bouger";
            case VideoType.health:
              return "Santé";
            default:
              return "";
          }
        } else {
          return "";
        }
      },
    },
  },
  data() {
    return {
      deleteDialog: false,
      deleteLoading: false,
      updateLoading: false,
      thumbnailSrc: base64Images.thumbnailPlaceholder,
      thumbnailChanged: false,
      thumbnail: null as File | null,
      video: null as null | Video,
      categoryId: 0,
      coachId: 0,
      bodyPartId: 0,
      tags: [] as Array<string | Tag>,
      tagSearch: "",
      videoObjectLoaded: false,
      videoObjectEdited: false,
      videoLoading: true,
      difficultyElements: [
        {
          id: Level.rookie,
          title: "Débutant",
        },
        {
          id: Level.intermediate,
          title: "Intermédiaire",
        },
        {
          id: Level.pro,
          title: "Confirmé",
        },
      ],
      rules: rules(this),
      valid: false,
    };
  },
  watch: {
    async id() {
      // If ID, then we fetch the details
      if (this.id) {
        // Fetch video object
        this.getVideo();
      }
    },
    video: {
      deep: true,
      handler(newValue, oldValue) {
        // If the video changes while being "loaded", it means that the admin has edited it
        // we thus need to set it as "edited to allow the button to be clickable
        if (oldValue && this.videoObjectLoaded) {
          this.videoObjectEdited = true;
        }
      },
    },
  },
  methods: {
    async deleteVideo(id: number) {
      this.deleteLoading = true;
      await Request.shared.delete(`video/${id}`);
      await this.$store.dispatch("getVideos");
      this.$emit("updated");
      this.deleteLoading = false;
      this.showDialog = false;
    },
    async updateVideo() {
      try {
        this.updateLoading = true;
        if (this.video) {
          if (this.thumbnailChanged) {
            // If the user has deleted the thumbnail, we send the appropriate request (an empty thumbnail route)
            if (!this.thumbnailFile) {
              const thumbnailDeleted = await Request.shared.put(
                `video/${this.video.id}/thumbnail`
              );
            } else {
              // The user has set a new thumbnail, we need to create the media and assign it to the video
              const mediaBody = {
                type: MediaType.thumbnail,
                alt: this.thumbnailFile.name,
              };
              // We create the formdata
              const formData = new FormData();
              formData.append("body", JSON.stringify(mediaBody));
              formData.append("media", this.thumbnailFile);
              const media = await Request.shared.post("media", formData);
              // Once we got the media, we can assign the thumbnail to the video
              const assignedThumbnail = await Request.shared.put(
                `video/${this.video.id}/thumbnail/${media.data.id}`
              );
            }
          }
          // Thumbnail has been taken care of, we can assign the category, coach and bodypartId if they are set
          if (this.categoryId) {
            const categorySet = await Request.shared.put(
              `video/${this.video.id}/category/${this.categoryId}`
            );
          }
          if (this.coachId) {
            // Coach is a little tricky, as for now we only allow one coach per video, we need to "purge" the existing coaches
            // Before assigning a new one
            for (const coach of this.video.Coaches!) {
              const coachRemoved = await Request.shared.delete(
                `video/${this.video.id}/coach/${coach.id}`
              );
            }
            const coachSet = await Request.shared.post(
              `video/${this.video.id}/coach/${this.coachId}`
            );
          }
          if (this.bodyPartId) {
            const bodyPartSet = await Request.shared.put(
              `video/${this.video.id}/bodypart/${this.bodyPartId}`
            );
          }

          if (this.tags) {
            // First we remove all the tags if there are any :
            if (this.video.Tags) {
              for (const tag of this.video.Tags) {
                const deletedTag = await Request.shared.delete(
                  `video/${this.video.id}/tag/${tag.id}`
                );
              }
            }
            let tagFound = undefined as Tag | undefined;
            // Tag handling (we are forced to cycle through every tag)
            for (const tag of this.processedTags) {
              if (typeof tag == "object") {
                // We try to find a tag with the same name (case insensitive)
                tagFound = (this.$store.state.tags as Tag[]).find((value) => {
                  return (tag as Tag).value == value.value;
                });
              } else {
                // We try to find a tag with the same name (case insensitive)
                tagFound = (this.$store.state.tags as Tag[]).find((value) => {
                  return tag == value.value;
                });
              }

              if (tagFound) {
                // If the tag exists, we assign it
                const assignedTag = await Request.shared.post(
                  `video/${this.video.id}/tag/${tagFound.id}`
                );
              } else {
                // We need to create the tag first, then assign it
                const postedTag = await Request.shared.post("tag", {
                  value: tag,
                });
                const assignedTag = await Request.shared.post(
                  `video/${this.video.id}/tag/${postedTag.data.id}`
                );
              }
            }
          }

          // We need to get rid of these includes to prevent wrecking havoc
          delete this.video.Category;
          delete this.video.Thumbnail;
          delete this.video.BodyPart;
          delete this.video.Tags;
          delete this.video.Coaches;

          // Update Video
          const videoUpdated = await Request.shared.put(
            `video/${this.video.id}`,
            this.video
          );
        }
        this.updateLoading = false;
        this.$store.dispatch("showAlert", {
          message: "La vidéo a été mise à jour",
          color: "success",
          timeout: 4000,
        });
        this.$emit("updated");
        this.videoObjectEdited = false;
        this.thumbnailChanged = false;
      } catch (error) {
        console.error(error);
      }
      // First we need to take care of the thumbnail if it has changed
    },
    parseTypeEnum(type: VideoType) {
      switch (type) {
        case VideoType.move:
          return "move";
        case VideoType.health:
          return "health";
        default:
          return "";
      }
    },
    formattedDuration(length: number) {
      const seconds = length % 60;
      const minutes = Math.floor((length / 60) % 60);
      const hours = Math.floor(length / 3600);
      return `${hours ? `${hours}:` : ""}${
        minutes < 10 ? `0${minutes}` : minutes
      }:${seconds < 10 ? `0${seconds}` : seconds}`;
    },
    selectImage() {
      (this.$refs.thumbnailSelector as any).$refs.input.click();
    },
    handleDrop(e: DragEvent) {
      if (e.dataTransfer!.files.length) {
        this.thumbnailFile = e.dataTransfer!.files[0];
      }
    },
    validateThumbnail(file: File) {
      if (file.type == "image/png" || file.type == "image/jpeg") {
        // We check the file size
        if (file.size < 300000) {
          return true;
        } else {
          throw {
            message:
              "Le fichier est trop volumineux, sa taille doit être inférieure à 300ko",
          };
        }
      } else {
        throw {
          message: "Veuillez utiliser un fichier .jpg ou .png",
        };
      }
    },
    tagsChanged() {
      this.videoObjectEdited = true;
    },
    async getVideo() {
      try {
        this.videoObjectLoaded = false;
        const video = await Request.shared.get(`video/${this.id}`);
        const videoObject = video.data as Video;
        this.video = videoObject;
        if (this.video.Category) {
          this.categoryId = this.video.Category.id;
        }
        if (this.video.BodyPart) {
          this.bodyPartId = this.video.BodyPart.id;
        }
        if (this.video.Coaches && this.video.Coaches.length) {
          this.coachId = this.video.Coaches[0].id;
        }
        this.tags = [...this.video.Tags!];
        this.videoObjectEdited = false;
        this.videoObjectLoaded = true;
        // If we're here, we can already show the video information
        this.videoLoading = false;
        // If there is a thumbnail, we get the image
        if (videoObject.Thumbnail) {
          try {
            const imageRequest = await Request.shared.get(
              `content/${this.$store.state.admin.uuid}/media/${videoObject.Thumbnail.filename}`,
              {
                responseType: "blob",
              }
            );
            this.thumbnailSrc = URL.createObjectURL(imageRequest.data);
          } catch (error) {
            console.log(error);
            // Show alert but don't close
          }
        }
      } catch (error) {
        // Show alert and close
      }
    },
  },
});
