





















































































import Vue from "vue";
import request from "@/modules/request";
import Video from "@/store/models/Video";
import AddVideoDialog from "@/components/AddVideoDialog.vue";
import EditVideoDialog from "@/components/EditVideoDialog.vue";
import Request from "@/modules/request";
import { Level } from "@/store/interfaces/Level";
import Media from "@/store/models/Media";
import base64Images from "@/modules/base64-images";
import prettyBytes from "pretty-bytes";

export default Vue.extend({
  components: {
    AddVideoDialog,
    EditVideoDialog,
  },
  computed: {
    diskColor(): string {
      if (this.diskPercentage < 75) return "black";
      else if (this.diskPercentage < 95) return "warning";
      else return "error";
    },
    prettyDiskInfo() {
      const used = prettyBytes(this.diskInfo.size - this.diskInfo.free);
      const size = prettyBytes(this.diskInfo.size);
      return {
        used,
        size,
      };
    },
    diskPercentage(): number {
      return (
        ((this.diskInfo.size - this.diskInfo.free) / this.diskInfo.size) * 100
      );
    },
    prettyDiskPercentage(): string {
      return this.diskPercentage.toFixed(1) + "%";
    },
  },
  data() {
    return {
      diskInfo: {
        free: 0,
        size: 0,
      },
      editIndex: 0,
      editDialog: false,
      dialogs: {} as Record<number, boolean>,
      loadings: {} as Record<number, boolean>,
      deleteDialog: false,
      progress: 0,
      status: "waiting",
      file: null as null | File,
      loading: false,
      thumbnails: [] as Array<string>,
      headers: [
        {
          text: "Type",
          value: "type",
          width: 60,
        },
        {
          text: "",
          value: "thumbSrc",
          width: 200,
          sortable: false,
        },
        {
          text: "Difficulté",
          value: "difficulty",
          width: 50,
          sortable: false,
        },
        {
          text: "Titre",
          value: "name",
        },
        {
          text: "Catégorie",
          value: "Category.name",
        },
        {
          text: "Encodage",

          value: "encodingString",
        },
        {
          text: "Publique",
          value: "available",
          align: "center",
          width: 50,
        },
      ],
      addDialog: false,
      loadContent: true,
    };
  },
  async beforeMount() {
    this.$store.dispatch("getCategories", "blog");
    this.$store.dispatch("getCategories", "move");
    this.$store.dispatch("getCategories", "health");
    this.$store.dispatch("getTags");
    this.$store.dispatch("getBodyParts");
    this.$store.dispatch("getCoaches");
    await this.$store.dispatch("getVideos");

    // We get the disk infos
    try {
      this.diskInfo = (await Request.shared.get("video/checkdisk")).data;
    } catch (error) {
      console.error(error);
    }
  },
  methods: {
    async currentItems(items: Video[]) {
      this.loadContent = true;
      try {
        for (const item of items) {
          // If there is a thumbnail
          if (item.Thumbnail) {
            // if it's not already been loaded
            if (item.thumbnailSrc == base64Images.thumbnailPlaceholder) {
              const blob = await request.shared.get(
                `content/${this.$store.state.admin.uuid}/media/${item.Thumbnail.filename}`,
                {
                  responseType: "blob",
                }
              );
              item.thumbnailSrc = URL.createObjectURL(blob.data);
            }
          }
        }
      } catch (error) {
        console.error(error);
      }
      // We need the array of thumbnails, which will be stored in base64 into memory

      this.loadContent = false;
    },
    getDifficultyIcon(difficulty: Level) {
      switch (difficulty) {
        case Level.rookie:
          return "mdi-signal-cellular-1";
        case Level.intermediate:
          return "mdi-signal-cellular-2";
        case Level.pro:
          return "mdi-signal-cellular-3";
      }
    },
    async toggleVisibility(item: Video) {
      try {
        await Request.shared.put(`video/${item.id}`, {
          available: item.available,
        });
      } catch (error) {
        console.error(error);
      }
    },
    postUpdateCycle() {
      this.$store.dispatch("getVideos");
      this.$store.dispatch("getTags");
    },
    closeEditDialog() {
      this.editIndex = 0;
      this.editDialog = false;
    },
    closeAddDialog() {
      this.addDialog = false;
      this.postUpdateCycle();
    },
    openEditDialog(item: Record<string, any>) {
      this.editIndex = item.id;
      this.editDialog = true;
    },
    reportProgress(event: ProgressEvent) {
      this.progress = Math.floor((event.loaded * 100) / event.total);
    },
    success() {
      this.loading = false;
      this.status = "SUCCESS!";
    },
    sendVideo() {
      this.loading = true;
      const body = {
        type: 3,
        name: "blablabla",
        description: "There you go",
        difficulty: 1,
      };
      this.status = "In progress";
      request.postVideo(this.file!, body, this.reportProgress, this.success);
    },
    async deleteVideo(item: number) {
      try {
        this.loadings[item] = true;
        await Request.shared.delete(`video/${item}`);
        await this.$store.dispatch("getVideos");
        this.dialogs[item] = false;
        this.loadings[item] = false;
      } catch (error) {
        this.$store.dispatch("showAlert", {
          message: "Impossible de supprimer la vidéo pour le moment",
          color: "warning",
          timeout: 4000,
        });
      }
    },
    getEncodingStatus(item: Video) {
      if (item.encodingError) {
        return "Erreur!";
      } else if (item.needsEncoding) {
        return "En attente...";
      } else if (!item.encoded) {
        return `En cours : ${item.encodingProgress}%`;
      } else if (item.encoded) {
        return "Terminé";
      } else {
        return "Inconnu";
      }
    },
    formattedDuration(length: number) {
      const seconds = length % 60;
      const minutes = Math.floor((length / 60) % 60);
      const hours = Math.floor(length / 3600);
      return `${hours ? `${hours}:` : ""}${
        minutes < 10 ? `0${minutes}` : minutes
      }:${seconds < 10 ? `0${seconds}` : seconds}`;
    },
  },
});
